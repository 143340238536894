"use client";

import React, { ReactNode } from 'react';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';

interface MainLayoutProps {
  children: ReactNode;
  fullWidthHero?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, fullWidthHero = false }) => {
  return (
    <div className="relative min-h-screen transition-colors duration-300 bg-[#0B0B0C]">
      <div className="relative z-10">
        <Navbar />
        
        {fullWidthHero ? (
          // For pages with full-width hero sections (like home page)
          <div>{children}</div>
        ) : (
          // For pages with container constraints
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <main className="flex flex-col justify-between items-stretch">
              {children}
            </main>
          </div>
        )}
        
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;