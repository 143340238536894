"use client";

import React, { useEffect, useState } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { Link as ScrollLink } from 'react-scroll';
import { SignedIn, SignedOut } from '@clerk/nextjs';
import Link from 'next/link';
import Image from 'next/image';

const Navbar = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [isHomePage, setIsHomePage] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    setIsHomePage(pathname === '/');
  }, [pathname]);

  const handleRedirect = (isSignIn: boolean = false) => {
    router.push(isSignIn ? '/sign-in?' : '/twitch-bot'); 
    setIsMobileMenuOpen(false);
  };

  const handleNavigation = (sectionId: string) => {
    if (!isHomePage) {
      router.push('/');
      setTimeout(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
    setIsMobileMenuOpen(false);
  };

  const NavItem = ({ to, children }) => {
    if (isHomePage) {
      return (
        <ScrollLink
          to={to}
          smooth={true}
          duration={500}
          className="text-gray-300 hover:text-white transition-colors duration-200 text-md font-medium cursor-pointer uppercase"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          {children}
        </ScrollLink>
      );
    }
    return (
      <button
        onClick={() => handleNavigation(to)}
        className="text-gray-300 hover:text-white transition-colors duration-200 text-md font-medium whitespace-nowrap uppercase"
      >
        {children}
      </button>
    );
  };

  const navigationItems = (isMobile) => (
    <>
      <div
        className={`${
          isMobile
            ? 'flex flex-col space-y-4 whitespace-nowrap'
            : 'hidden lg:flex items-center space-x-8 whitespace-nowrap'
        }`}
      >
        <div>
          <NavItem to="features">Features</NavItem>
        </div>
        <Link
          href="/pricing"
          className="text-gray-300 hover:text-white transition-colors duration-200 text-md font-medium whitespace-nowrap uppercase"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          Pricing
        </Link>
        <a
          href="https://affiliate.saved.gg"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-300 hover:text-white transition-colors duration-200 text-md font-medium whitespace-nowrap uppercase"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          Affiliate
        </a>
        <Link
          href="/about"
          className="text-gray-300 hover:text-white transition-colors duration-200 text-md font-medium whitespace-nowrap uppercase"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          About
        </Link>
        <div className={`relative ${isMobile ? 'w-full' : ''}`}>
          {/* Add a invisible bridge element to maintain hover */}
          <div
            className="absolute -bottom-2 left-0 w-full h-2"
            onMouseEnter={() => !isMobile && setIsResourcesOpen(true)}
          />
          <div
            className="relative"
            onMouseEnter={() => !isMobile && setIsResourcesOpen(true)}
            onMouseLeave={() => !isMobile && setIsResourcesOpen(false)}
          >
            <button
              className="text-gray-300 hover:text-white transition-colors duration-200 text-md font-medium flex items-center whitespace-nowrap uppercase"
              onClick={() => isMobile && setIsResourcesOpen(!isResourcesOpen)}
            >
              Resources
              <svg
                className={`ml-1 h-4 w-4 transition-transform duration-200 ${
                  isResourcesOpen ? 'rotate-180' : ''
                }`}
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M19 9l-7 7-7-7"></path>
              </svg>
            </button>
            <div
              className={`absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-[#0B0B0C] ring-1 ring-black ring-opacity-5 transition-all duration-200 ease-in-out transform z-[100]
                ${
                  isResourcesOpen
                    ? 'opacity-100 translate-y-0'
                    : 'opacity-0 -translate-y-1 pointer-events-none'
                }`}
            >
              <div className="py-1">
                <Link
                  href="https://blog.saved.gg"
                  className="block px-4 py-2 text-md font-medium text-gray-300 hover:text-white hover:bg-[#1a1a1c] transition-colors duration-200 uppercase"
                  onClick={() => isMobile && setIsMobileMenuOpen(false)}
                >
                  Blog
                </Link>
                <Link
                  href="/faq"
                  className="block px-4 py-2 text-md font-medium text-gray-300 hover:text-white hover:bg-[#1a1a1c] transition-colors duration-200 uppercase"
                  onClick={() => isMobile && setIsMobileMenuOpen(false)}
                >
                  FAQ
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <header className="bg-[#0B0B0C] bg-opacity-0 top-0 z-50">
      <div className="mx-auto px-10 pt-3">
        <div className="flex justify-between items-center h-16">
          {/* Logo Section */}
          <div className="flex items-center flex-shrink-0">
            <Link href="/">
              <div className="relative h-8 w-auto">
                <Image
                  src="https://storage.googleapis.com/saved_media_assets/SavedLogo.png"
                  alt="Saved Logo"
                  width={128}
                  height={32}
                  priority
                  className="h-8 w-auto"
                />
              </div>
            </Link>
          </div>

          {/* Desktop Navigation */}
          {navigationItems(false)}

          {/* Desktop Buttons */}
          <div className="hidden lg:flex items-center space-x-6">
            <a
              href="https://discord.gg/MqdzdRr6DP"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-white transition-colors duration-200 text-sm font-bold whitespace-nowrap flex items-center gap-2"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20.317 4.37a19.791 19.791 0 0 0-4.885-1.515a.074.074 0 0 0-.079.037c-.21.375-.444.864-.608 1.25a18.27 18.27 0 0 0-5.487 0a12.64 12.64 0 0 0-.617-1.25a.077.077 0 0 0-.079-.037A19.736 19.736 0 0 0 3.677 4.37a.07.07 0 0 0-.032.027C.533 9.046-.32 13.58.099 18.057a.082.082 0 0 0 .031.057a19.9 19.9 0 0 0 5.993 3.03a.078.078 0 0 0 .084-.028a14.09 14.09 0 0 0 1.226-1.994a.076.076 0 0 0-.041-.106a13.107 13.107 0 0 1-1.872-.892a.077.077 0 0 1-.008-.128a10.2 10.2 0 0 0 .372-.292a.074.074 0 0 1 .077-.01c3.928 1.793 8.18 1.793 12.062 0a.074.074 0 0 1 .078.01c.12.098.246.198.373.292a.077.077 0 0 1-.006.127a12.299 12.299 0 0 1-1.873.892a.077.077 0 0 0-.041.107c.36.698.772 1.362 1.225 1.993a.076.076 0 0 0 .084.028a19.839 19.839 0 0 0 6.002-3.03a.077.077 0 0 0 .032-.054c.5-5.177-.838-9.674-3.549-13.66a.061.061 0 0 0-.031-.03zM8.02 15.33c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.956-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.956 2.418-2.157 2.418zm7.975 0c-1.183 0-2.157-1.085-2.157-2.419c0-1.333.955-2.419 2.157-2.419c1.21 0 2.176 1.096 2.157 2.42c0 1.333-.946 2.418-2.157 2.418z"/>
              </svg>
            </a>
            <SignedOut>
              <Link href="/sign-in">
                <button className="w-full bg-white hover:bg-gray-100 text-black px-6 py-2 rounded-md text-md font-semibold transition-colors duration-200">
                  Sign In
                </button>
              </Link>
            </SignedOut>
            <SignedIn>
              <Link href="/streams">
                <button className="w-full bg-white hover:bg-gray-100 text-black px-6 py-2 rounded-md text-md font-semibold transition-colors duration-200">
                  Dashboard
                </button>
              </Link>
            </SignedIn>
          </div>

          {/* Mobile menu button */}
          <button
            className="lg:hidden text-gray-300 hover:text-white focus:outline-none"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle menu"
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`lg:hidden transition-all duration-300 ease-in-out ${
          isMobileMenuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
        }`}
      >
        <div className="px-4 pt-2 pb-4 space-y-4 bg-[#0B0B0C] bg-opacity-50 border-t border-gray-800">
          {navigationItems(true)}

          {/* Mobile Auth Buttons */}
          <div className="pt-4 border-t border-gray-800">
            <SignedOut>
              <Link href="/sign-in" onClick={() => setIsMobileMenuOpen(false)}>
                <button className="w-full bg-white hover:bg-gray-100 text-black px-6 py-2 rounded-md text-md font-semibold transition-colors duration-200">
                  Sign In
                </button>
              </Link>
            </SignedOut>
            <SignedIn>
              <Link href="/streams" onClick={() => setIsMobileMenuOpen(false)}>
                <button className="w-full bg-white hover:bg-gray-100 text-black px-6 py-2 rounded-md text-md font-semibold transition-colors duration-200">
                  Dashboard
                </button>
              </Link>
            </SignedIn>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;